import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Send from '@material-ui/icons/Send';
import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import { ServiceOrder } from 'src/generated/graphql';
import AppTextInput from 'src/modules/Common/Form/AppTextInput';

interface Props {
    onNewMessage: (message: string) => void
    serviceOrder: ServiceOrder
    methods: UseFormMethods
}

export default function SmsSendMessage(props: Props) {
    const { serviceOrder, onNewMessage, methods } = props;

    const { register, reset, handleSubmit, watch } = methods;

    const currentMessage = watch('message');

    const onSubmit = (formValues: any) => {
        if (formValues.message) {
            onNewMessage(formValues.message);

            reset();
        }
    };

    return (
        <div className="sms-send-message">
            <form onSubmit={handleSubmit(onSubmit)}>
                <span>Hej {serviceOrder.customer?.name},</span>
                <AppTextInput
                    name="message"
                    placeholder={'Meddelande till kund'}
                    inputRef={register}
                    multiline
                    InputProps={{
                        endAdornment: currentMessage ? (
                            <InputAdornment position="end">
                                <IconButton
                                    component="span"
                                    onClick={handleSubmit(onSubmit)}
                                >
                                    <Send />
                                </IconButton>
                            </InputAdornment>
                        ) : null,
                    }}
                />
                <span>
                    Mvh Sportson {serviceOrder.store?.name}
                </span>
            </form>
        </div>
    );
}
