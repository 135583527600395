import { Box, ListItem, Tooltip } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import SmsIcon from '@mui/icons-material/Sms';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import React, { useEffect, useRef } from 'react';
import { GetSmsRecordsQueryResult, NotificationType, SmsRecordStatus } from 'src/generated/graphql';
import AppButton from 'src/modules/Common/Form/AppButton';
import { formatDateTime } from 'src/modules/Common/helpers/dates';
import { notificationTypeText } from 'src/modules/ServiceOrder/utils/smsHelper';

const useStyles = makeStyles(() => ({
    root: {
        height: 400,
        overflowY: 'scroll',
    },
    list: {
        paddingTop: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
    },
    time: {
        display: 'inline-block',
        width: 125,
    },
    icon: {
        float: 'right',
        height: 16,
    },
}));

interface Props {
    items: NonNullable<GetSmsRecordsQueryResult['data']>['smsRecords']['items'];
    hasMore: boolean;
    loadMore: () => void;
}

export default function AlignItemsList({ items, hasMore, loadMore }: Props) {
    const classes = useStyles();

    const scrollElement = useRef<HTMLElement | any>(null);
    const itemCount = items?.length || 0;

    useEffect(() => {
        // scroll to bottom
        const element = scrollElement.current;

        if (element) {
            element.scrollTop = element.scrollHeight;
        }
    }, [itemCount, scrollElement]);


    return (
        <Card variant='outlined' className={classes.root} ref={scrollElement}>
            <List className={classes.list}>
                {hasMore && (<AppButton onClick={loadMore}>Ladda fler</AppButton>)}
                {items.map((item, i) => {
                    const isCompleted = item.status === SmsRecordStatus.Delivered || item.status === SmsRecordStatus.Success;
                    const isFailed = item.status === SmsRecordStatus.Failed;
                    const isUndecided = !isCompleted && !isFailed;

                    return <ListItem key={i} className={`sms-message-bubble ${item.type === NotificationType.CustomerResponse ? "left" : "right"}`}>
                        <Box fontSize={'12px'} padding={0} display={"flex"} justifyContent={"space-between"} flexGrow={1} width={"100%"}>
                            {formatDateTime(item.createdAt)}
                            <Tooltip title={smsStatusTooltip(item.status)}>
                                <Box maxHeight={20}>
                                    {isUndecided && <SmsIcon color='info' />}
                                    {isFailed && <SmsFailedIcon color='error' />}
                                    {isCompleted && <MarkChatReadIcon color='success' />}
                                </Box>
                            </Tooltip>
                        </Box>
                        <Box fontWeight={700}>
                            {notificationTypeText(item.type)}
                        </Box>
                        {item.message}
                    </ListItem>
                }
                )}
            </List>
        </Card>
    );
}

function smsStatusTooltip(status: SmsRecordStatus) {
    switch (status) {
        case SmsRecordStatus.Accepted:
            return 'Meddelandet har accepterats av Twilio';
        case SmsRecordStatus.Sending:
            return 'Meddelandet skickas';
        case SmsRecordStatus.Sent:
            return 'Meddelandet har skickats';
        case SmsRecordStatus.Failed:
            return 'Meddelandet misslyckades';
        case SmsRecordStatus.Delivered:
            return 'Meddelandet har levererats';
        case SmsRecordStatus.Success:
            return 'Meddelandet har levererats';
        case SmsRecordStatus.Queued:
            return 'Meddelandet har hanterats';
        default:
            return '';
    }
}
